import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WOW } from 'wowjs/dist/wow.min';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
declare var $: any;

declare let Email: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  totalvisitor: any;
  marque: any;
  cnt: any;
  ipAddress: any;
  counter = 0;
  elementlength: any;
  showcnter: boolean;
  constructor(private _snackBar: MatSnackBar,private dialog: MatDialog, private myRoute: Router, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {}

  ngAfterViewInit() {
    new WOW().init();
  }

  ngOnDestroy(): void {
  }

 

  ngOnInit() {

    window.onbeforeunload = function () { window.scrollTo(0, 0); }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

    }
    else {
      $('#tsts1').addClass('active');

      $(document).ready(function () {

        var scroll_pos = 0;
        $(document).scroll(function () {
          scroll_pos = $(this).scrollTop();

           //home tab section
          if (scroll_pos < 2700) {
            $('.tst1').addClass('active');
            $('.tst2').removeClass('active');
            $('.tst3').removeClass('active');
            $('.tst4').removeClass('active');
          }

          //product tab section
          if (scroll_pos > 2650) {

            $('.tst2').addClass('active');
            $('.tst1').removeClass('active');
            $('.tst3').removeClass('active');
            $('.tst4').removeClass('active');
          }

          //career tab section
          if (scroll_pos > 4600) {

            $('.tst3').addClass('active');
            $('.tst1').removeClass('active');
            $('.tst2').removeClass('active');
            $('.tst4').removeClass('active');
          }

          //contact us tab section
          if (scroll_pos > 5000) {

            $('.tst4').addClass('active');
            $('.tst1').removeClass('active');
            $('.tst2').removeClass('active');
            $('.tst3').removeClass('active');
          }
        });
      });
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      console.log("mobile device");

      $(document).ready(function () {

        $(".navcss nav").css('background-color', '#607D8B ');
        $(".tst").css('color', '#fff');

      });

    } else {
      // false for not mobile device
      $(document).ready(function () {
        var scroll_pos = 0;
        $(document).scroll(function () {
          scroll_pos = $(this).scrollTop();
          if (scroll_pos > 830) {
            $(".navcss nav").css('background-color', '#607D8B ');
            $(".tst").css('color', '#fff');
          } else {
            $(".navcss nav").css('background-color', ' rgba(191, 201, 202,0.7)');
            $(".tst").css('color', '#7B241C');
          }
        });
      });
    }
  }

  @ViewChild('scheduledemo') scheduledemo: TemplateRef<any>;
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  openDialogWithoutRef() {
    this.dialog.open(this.scheduledemo);
  }

  scrollToElement($element): void {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      console.log($element);
      $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      $(function () {
        $('a').click(function () {
          $('#tst').removeClass('active');
          $('a').removeClass('active');
          $(this).addClass('active');
        });
      });
    }
    else {
      console.log($element);
      $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      $(function () {
        $('a').click(function () {
          $('#tst').removeClass('active');
          $('a').removeClass('active');
          $(this).addClass('active');
        });
      });
    }
  }

  submit(data) {
    // console.log("data :", data)
    if (data) {

      Email.send({
        Host: "smtp.gmail.com",
        Username: "test.primustechsys@gmail.com",
        Password: "Primus@123",
        // To: "info@arthsethu.in",
        To: "sales@arthnext.com",
        From: "test.primustechsys@gmail.com",
        Subject: "ArthSethu Contact Us",
        Body: "<p>Details are </p> <p> Name :  " + data.name + " </p>   <p> Email :  " + data.email + " </p>   <p> Contact :  " + data.contact + " </p>     <p> Subject :  " + data.subject + " </p>   <p> Message :  " + data.msg + " </p>"
      }).then(message => { this._snackBar.open('Sent Mail Successfully!', 'Close', {
        duration: 1000,
        verticalPosition: 'top', // 'top' | 'bottom'
        horizontalPosition: 'end'//'start' | 'center' | 'end' | 'left' | 'right'
        
      }); });
    }
  }

  submitSchedule(data) {
    // console.log("data :", data)
    if (data) {

      Email.send({
        Host: "smtp.gmail.com",
        Username: "test.primustechsys@gmail.com",
        Password: "Primus@123",
        // To: "sourabhambarshetti@gmail.com",
        To: "sales@arthnext.com",
        From: "test.primustechsys@gmail.com",
        Subject: "ArthSethu Schedule Demo",
        Body: "<p>Details are </p> <p> Name :  " + data.name + " </p>   <p> Email :  " + data.email + " </p>   <p> Contact :  " + data.contact + " </p>     <p> Subject :  " + data.subject + " </p>   <p> Message :  " + data.msg + " </p>"
      }).then(message =>  {  
        
        this._snackBar.open('Sent Mail Successfully!', 'Close', {
        duration: 1000,
        verticalPosition: 'top', // 'top' | 'bottom'
        horizontalPosition: 'end' //'start' | 'center' | 'end' | 'left' | 'right'
     
      });
    });
    }
  }
}